import React from 'react';
import { Link } from 'gatsby';

import { CONTACT_US } from '../constants/routes';

const ContactButton = ({ outline }) => {
  const renderClassName = (outline = false) => {
    if (outline) {
      return "border border-[#FB3640] rounded-full text-xs lg:text-base px-4 py-1 lg:px-12 lg:py-2 lg:font-semibold text-[#FB3640] hover:bg-[#E13039] hover:text-white";
    }

    return "bg-[#FB3640] border-none rounded-full text-xs lg:text-base px-4 py-1 lg:px-12 lg:py-2 lg:font-semibold text-white hover:bg-[#E13039] hover:text-white";
  };

  return (
    <Link
      to={CONTACT_US}
      className={renderClassName(outline)}
    >
      Contact Us
    </Link>
  );
};

export default ContactButton;
