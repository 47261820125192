import React from 'react';
import { Link } from 'gatsby';

import ContactButton from './ContactButton';
import * as ROUTES from '../constants/routes';

const Footer = () => {
  return (
    <footer className="px-12 py-8 md:p-20 text-white bg-[#270356]">
      <div className="md:flex md:justify-around md:gap-x-8">
        <div className="md:mt-0 md:flex-1">
          <h4 className="font-semibold text-4xl md:text-2xl lg:text-5xl">
            <Link to={ROUTES.HOME}>
              Hillside Lab
            </Link>
          </h4>
          <p className="font-extralight text-lg md:text-sm lg:text-2xl tracking-wider mt-1">
            Bringing Ideas to Life
          </p>
          <p className="hidden md:block mt-8 text-sm md:text-xs font-light">
            &copy;{" "}
            <Link to={ROUTES.HOME}>
              {new Date().getFullYear()} Hillside Lab Inc.
            </Link>
          </p>
          <p className="hidden md:block mt-1 text-sm md:text-xs font-extralight">
            All rights reserved
          </p>
        </div>

        <div className="mt-6 md:mt-0 lg:px-12 xl:px-16 md:flex-1">
          <p className="md:mt-2 lg:mt-4">
            <ContactButton outline={false} />
          </p>
        </div>

        <div className="mt-12 md:mt-0 xl:pl-16">
          <h6 className="font-semibold text-base">
            Hillside Lab
          </h6>
          <p className="mt-4 font-extralight text-sm">
            <Link to={ROUTES.HOME}>
              Home
            </Link>
          </p>
          <p className="mt-2 font-extralight text-sm">
            <Link to={ROUTES.CUSTOM_SOLUTIONS}>
              Custom Solutions
            </Link>
          </p>
          <p className="mt-2 font-extralight text-sm">
            <Link to={ROUTES.CAREERS}>
              Careers
            </Link>
          </p>
        </div>

        <div className="mt-8 md:mt-0 lg:grow xl:pl-20 md:flex-1">
          <h6 className="font-semibold text-base">
            Legal Stuff
          </h6>
          <p className="mt-4 font-extralight text-sm">
            <Link to={ROUTES.TERMS_OF_USE}>
              Terms of Use
            </Link>
          </p>
          <p className="mt-2 font-extralight text-sm">
            <Link to={ROUTES.PRIVACY_POLICY}>
              Privacy Policy
            </Link>
          </p>
          <p className="mt-2 font-extralight text-sm">
            <Link to={ROUTES.THIRD_PARTY_DISCLAIMER}>
              Third Party Disclaimer
            </Link>
          </p>
        </div>

        <div className="mt-16 md:hidden">
          <p className="mt-8 text-xs font-light">
            &copy;{" "}
            <Link to={ROUTES.HOME}>
              {new Date().getFullYear()} Hillside Lab Inc.
            </Link>
          </p>
          <p className="mt-1 text-xs font-extralight">
            All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
