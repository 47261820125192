import React, { useEffect, useState } from 'react';
import { navigate, Link } from 'gatsby';

import Layout from '../components/Layout';
import TermsOfUse from '../components/TermsOfUse';
import PrivacyPolicy from '../components/PrivacyPolicy';
import ThirdPartyDisclaimer from '../components/ThirdPartyDisclaimer';
import SEO from '../components/SEO';

const Legal = ({ location }) => {
  const [nav, setNav] = useState('#terms-of-use');

  useEffect(() => {
    const hashes = ['#terms-of-use', '#privacy-policy', '#third-party-disclaimer'];

    if (hashes.includes(location.hash)) {
      setNav(location.hash);
    }
  }, [location.hash]);

  const handleChange = (e) => {
    setNav(e.target.value);

    navigate(e.target.value);
  };

  return (
    <Layout>
      <SEO title="Legal" />
      <div className="my-12 px-6 lg:px-48">
        <div className="w-full lg:hidden">
          <select
            className="w-full h-10 px-3 text-base rounded-lg border"
            aria-label="In page navigation"
            value={nav}
            onChange={handleChange}
          >
            <option
              value="#terms-of-use"
            >
              Terms of Use
            </option>
            <option
              value="#privacy-policy"
            >
              Privacy Policy
            </option>
            <option
              value="#third-party-disclaimer"
            >
              Third Party Disclaimer
            </option>
          </select>
        </div>

        <div className="lg:grid lg:grid-flow-col">
          <div className="hidden lg:block lg:col-span-2">
            <div className="border-r pr-8 mt-12">
              <ul className="text-right">
                <li>
                  <Link
                    to="#terms-of-use"
                    onClick={() => setNav('#terms-of-use')}
                  >
                    Terms of Use
                  </Link>
                </li>
                <li className="mt-6">
                  <Link
                    to="#privacy-policy"
                    onClick={() => setNav('#privacy-policy')}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="mt-6">
                  <Link
                    to="#third-party-disclaimer"
                    onClick={() => setNav('#third-party-disclaimer')}
                  >
                    Third Party Disclaimer
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="lg:col-span-4 lg:pl-8">
            <TermsOfUse />
            <PrivacyPolicy />
            <ThirdPartyDisclaimer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Legal;
