import React from 'react';

const ThirdPartyDisclaimer = () => {
  return (
    <div id="third-party-disclaimer">
      <h2 className="text-[#3C0F76] font-medium text-3xl mt-12 mb-6 font-serif">
        Third Party Disclaimer
      </h2>

      <p className="my-4">
        All product and company names are trademarks™ or registered® trademarks of their respective holders. Use of them does not imply any affiliation with or endorsement by them.
      </p>
    </div>
  );
};

export default ThirdPartyDisclaimer;
