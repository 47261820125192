import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div id="privacy-policy">
      <h2 className="text-[#3C0F76] font-medium text-3xl mt-12 mb-6 font-serif">
        Privacy Policy
      </h2>

      <p className="my-4">
        We take your privacy seriously. We may collect and store any personal information that you knowingly provide to us, as well as information that we automatically receive from your browser or device. The Services do not currently support “do not track” requests at this time, which means that we may collect information about your online activity both while you are using the Services and after you leave the Services. We may communicate with you if you’ve provided us the means to do so, unless you inform us that you do not want to receive communications from us. We do not rent or sell your personal information to anyone. We may share the personally identifiable information you provide us with third parties, but only as necessary to provide the Services. In addition, we may share information to third parties on a non-personal or aggregated basis such that any individual would not be able to be identified.
      </p>

      <p className="my-4">
        You may contact us by support [at] hillsidelab [dot] com to learn about what personal information of yours is being processed by us, to request access to such information, to rectify your inaccurate personal information in our possession, and/or to request erasure of some or all of your personal data in our systems. In order to allow us to comply with your request, you may need to provide us with personal data or other information, for example, to verify your identity and the nature of your request. Please note that we may not always be able to fully comply with your request, for example, if we are required to retain certain information about you in order to comply with applicable laws and regulations.
      </p>

      <h3 className="font-medium text-xl mt-8 mb-6 font-serif">
        Legal Notices
      </h3>

      <p className="my-4">
        You are responsible for all information, content, and materials you contribute, disclose, or share in any manner on or through the Services and you represent and warrant you have all rights necessary to do so. You are responsible for all your activity in connection with the Services.
      </p>

      <p className="my-4">
        Nothing on the Services constitutes professional or financial advice of any kind (including business, employment, investment advisory, accounting, tax, and/or legal advice). Advice from a suitably qualified professional should always be sought in relation to any particular matter or circumstance. Nothing on the Services constitutes or forms a part of any offer for sale or subscription of, or any invitation to offer to buy or subscribe for, any securities, nor should it or any part of it form the basis of, or be relied upon in connection with, any contract or commitment whatsoever.
      </p>

      <p className="my-4">
        THE SERVICES AND THE INFORMATION CONTAINED THEREIN ARE PROVIDED “AS IS.” NEITHER HILLSIDE LAB INC. NOR ANY OF ITS AFFILIATES IS PROVIDING ANY WARRANTIES AND REPRESENTATIONS REGARDING THE SERVICES. HILLSIDE LAB AND ITS AFFILIATES DISCLAIM ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND WITH REGARD TO THE SERVICES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, FREEDOM FROM VIRUSES OR OTHER HARMFUL CODE, OR FITNESS FOR A PARTICULAR PURPOSE. HILLSIDE LAB INC. AND ITS AFFILIATES DO NOT WARRANT THE ACCURACY, ADEQUACY, OR COMPLETENESS OF THE INFORMATION AND MATERIALS CONTAINED ON THE SERVICES AND EXPRESSLY DISCLAIM LIABILITY FOR ERRORS OR OMISSIONS IN THE MATERIALS AND INFORMATION.
      </p>

      <p className="my-4">
        To the fullest extent allowed by applicable law, under no circumstances and regardless of the form of action, whether in contract, warranty, tort (including negligence), strict liability, or otherwise, will Hillside Lab Inc. or its affiliates be liable to you or any other person for (i) any amount in excess of $100 or (ii) any consequential, incidental, special, punitive, or exemplary damages, regardless of whether Hillside Lab Inc. or its affiliates have been apprised of the likelihood of such damages occurring.
      </p>

      <p className="my-4">
        To the fullest extent allowed by applicable law, you agree to indemnify and hold Hillside Lab Inc., its affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any third-party claims relating to (a) your use of the Services (including any actions taken by a third-party using your account), and (b) your violation of these Terms.
      </p>

      <p className="my-4">
        You and Hillside Lab Inc. agree that these Terms are the complete and exclusive statement of the mutual understanding between you and Hillside Lab Inc. and supersede all prior agreements and understandings relating to such subject matter. These Terms are governed by and will be construed under the laws of the State of California, without regard to the conflicts of laws provisions thereof. Any disputes relating to these Terms shall be resolved exclusively in the state or federal courts located in Los Angeles, California. These Terms are binding on you as well as your successors and permitted assigns. If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
