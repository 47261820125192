import React, { useState } from 'react';
import { Link } from 'gatsby';

import ContactButton from './ContactButton';
import * as ROUTES from '../constants/routes';

const Header = () => {
  const [menuExpanded, setMenuExpanded] = useState(false);

  return (
    <header className="px-6 py-2 md:px-12 md:py-4">
      <nav className="flex flex-row justify-around">
        <div className="flex-1 md:flex-none order-2 md:order-1">
          <h1 className="text-[#5B23A4] font-medium md:text-lg tracking-normal">
            <Link to={ROUTES.HOME}>
              Hillside Lab
            </Link>
          </h1>
        </div>

        <div className="flex-1 md:flex-auto order-1 md:order-2 md:grow" style={{ paddingTop: "2px" }}>
          <div className="hidden md:block">
            <Link
              to={ROUTES.CUSTOM_SOLUTIONS}
              className="font-extralight ml-8 text-slate-600 hover:text-black"
            >
              Custom Solutions
            </Link>
            <Link
              to={ROUTES.CAREERS}
              className="font-extralight ml-8 text-slate-600 hover:text-black"
            >
              Careers
            </Link>
          </div>
          <div className="md:hidden">
            <button
              className="flex items-center block px-3 py-2 text-gray-700 border border-gray-700 rounded"
              onClick={() => setMenuExpanded(!menuExpanded)}
            >
              <svg
                className="w-2 h-2 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
            <nav
              className={`${menuExpanded ? `block` : `hidden`}`}
            >
              {[
                {
                  route: ROUTES.CUSTOM_SOLUTIONS,
                  title: `Custom Solutions`,
                },
                {
                  route: ROUTES.CAREERS,
                  title: `Careers`,
                },
              ].map((link) => (
                <Link
                  className="block mt-4 text-sm text-gray-700 no-underline text-left"
                  key={link.title}
                  to={link.route}
                >
                  {link.title}
                </Link>
              ))}
            </nav>
          </div>
        </div>

        <div className="flex-1 md:flex-none order-3">
          <ContactButton outline={true} />
        </div>
      </nav>
    </header>
  );
};

export default Header;
