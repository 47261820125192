import React from 'react';

const TermsOfUse = () => {
  return (
    <div id="terms-of-use">
      <h2 className="text-[#3C0F76] font-medium text-3xl mt-12 mb-6 font-serif">
        Terms of Use
      </h2>

      <p className="my-4">
        Please read the following terms and conditions (“Terms”). They govern your use of this website and its associated services, including email newsletters, associated content distribution platforms, and public Hillside Lab Inc. online social media accounts (collectively, “the “Services”). By using the Services, you agree to be bound by these Terms. We may update these Terms from time to time and any modifications will be effective immediately upon posting unless we indicate otherwise. If you use the Services after any changes to the Terms have been posted, that means you agree to all of the changes. We suggest that you review these Terms periodically for changes.
      </p>

      <p className="my-4">
        Except as otherwise noted on the Services, all content and material on the Services — including information, photos, podcasts, blog posts, videos, graphics/charts, icons, code, design, and overall appearance — are the property of Hillside Lab Inc. and should not be used, modified, or reproduced without our prior written consent. All trademarks, trade names, and logos displayed on the Services are the property of Hillside Lab Inc., its affiliates, or their respective third-party owners, and the Services grants no license to them. Hillside Lab Inc is a registered trademarks in the United States and/or other countries and may not be used without the prior written permission of Hillside Lab Inc.
      </p>

      <p className="my-4">
        Any opinions expressed in the Services reflect Hillside Lab Inc. views or those of individual posters, and are subject to change. Certain information contained in the Services has been obtained from third-party sources, While taken from sources believed to be reliable, Hillside Lab Inc. has not independently verified such information and makes no representations about the enduring accuracy of the information or its appropriateness for a given situation. The content of this website is presented on an “as is” basis with no warranties, express or implied, of any kind with respect to the Services. Charts provided herein are for informational purposes only and should not be relied upon when making any investment decision.
      </p>

      <h3 className="font-medium text-xl mt-8 mb-6 font-serif">
        Third-Party Websites
      </h3>

      <p className="my-4">
        The Services may link to or integrate with other websites operated or content provided by third parties, and such other websites may link to this website. Hillside Lab Inc. has no control over any such other websites or their content and will have no liability arising out of or related to such websites or their content. The existence of any such link does not constitute an endorsement of such websites, the content of the websites, or the operators of the websites. Hillside Lab Inc. is providing these links to you only as a convenience. You release and hold Hillside Lab Inc. harmless from any and all liability arising from your use of any third-party website or service.
      </p>
    </div>
  );
};

export default TermsOfUse;
